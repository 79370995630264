import request from '@/utils/request'
import { AnalyParam } from '@/types/analy'
import { CustomInfo } from '@/types/common'
/**
 *  财务状况分析
 */
export const getAnalyList = (params: AnalyParam) =>
  request({
    url: '/blackcat/analysis/code',
    method: 'get',
    params
  })

/**
 *  子女信息
 */
export const fetchChildInfo = (params: CustomInfo) =>
  request({
    url: '/blackcat/customer/child',
    method: 'get',
    params
  })

/**
 *  财务计算字段
 */
export const getCacuList = (params: AnalyParam) =>
  request({
    url: 'blackcat/analysis/item/param',
    method: 'get',
    params
  })
