import { Component, Vue } from 'vue-property-decorator'
import { CommonMudule } from '@/store/modules/common'
import { ChartStoreObj, ChartReqRes, ChartStoreArr } from '@/types/analy'
import { getAnalyList, fetchChildInfo, getCacuList } from '@/api/analy'
import { riskTypeFind } from '@/api/portfolio'
import { AnalyModule } from '@/store/modules/analy'
import { barOption, barOption2, seriesBarColor, colors } from '@/store/modules/option'
import { formaChartOneRes, formaChartRes, floatReg } from '@/utils/tools'
import { cloneDeep } from 'lodash'
import { comdify } from '@/utils/validate'

@Component({
  name: 'AnalyMixins'
})
export default class extends Vue {
  initFetchData() {
    Promise.all([
      this.getTotalAs(),
      this.getAnalyListAction(),
      this.getAnalyListActionOther(),
      this.getChildInfoAction(),
      this.getFamalySecuritObj(),
      this.getFamalyConfig(),
      this.getChildIllData(),
    ])
  }
  public async getTotalAs() {
    const res = await getCacuList({
      customerId: CommonMudule.customerId
    })
    const res1 = await riskTypeFind({
      customerId: CommonMudule.customerId
    })

    if (res.statusCode === 0 && res1.statusCode === 0) {
      const { total_debt, total_asset } = res.data
      const { customerName } = res1.data
      const totalAsObj = {
        total_debt,
        total_asset,
        customerName
      }
      AnalyModule.SET_TOTAL_AS({ totalAs: totalAsObj })
    }
  }

  // 资产配置分析
  public async getFamalyConfig() {
    const asConfig = await this.fetchAction(AnalyModule.asConfig)
    console.log('getFamalyConfig asConfig', asConfig)
    AnalyModule.SET_AS_CONFIG({ asConfig })
  }

  // 子女教育
  public async getChildInfoAction() {
    const arr:string[] = []
    const res = await fetchChildInfo({ customerId: CommonMudule.customerId })
    if (res.statusCode === 0 && res.data) {
      res.data.forEach((ele:any) => {
        arr.push(ele.id)
      })
    }
    const fetchArr: any[] = []
    await arr.forEach((code: string) => {
      fetchArr.push(getAnalyList({
        analysisCode: 'code0021',
        customerId: CommonMudule.customerId,
        customerMemberId: code
      })
      )
    })
    const resArr = await Promise.all(fetchArr)
    const dataObjArr: any = []
    resArr.forEach(resItem => {
      if (resItem.statusCode === 0 && resItem.data) {
        if (!resItem.data.hide) {
          const tempOpt = cloneDeep(barOption)
          resItem.data.assetAnalysisItemTable && resItem.data.assetAnalysisItemTable.forEach((item: any) => {
            if (item.itemName === '当前覆盖度') {
              item.calFieldValue = item.calFieldValue + '%'
            } else {
              if (item.itemName === '当前缺口') {
                item.color = colors.red
              }
              if (floatReg(item.calFieldValue)) {
                item.calFieldValue = comdify(item.calFieldValue)
              }
            }
          })
          resItem.data.assetAnalysisItemGraph = formaChartOneRes(resItem.data.assetAnalysisItemGraph, 'itemName', 'calFieldValue')
          const nameList = resItem.data.assetAnalysisItemGraph.map((v:any) => { return v.name })
          tempOpt.series[0].data = resItem.data.assetAnalysisItemGraph
          tempOpt.xAxis[0].data = nameList
          resItem.data.barOption = tempOpt
        }
        dataObjArr.push(resItem.data)
      }
    })
    AnalyModule.SET_CHILD_OBJ({ childTeachObj: dataObjArr })
    return dataObjArr
  }

  // 子女重疾
  public async getChildIllData() {
    const arr:string[] = []
    const res = await fetchChildInfo({ customerId: CommonMudule.customerId })
    if (res.statusCode === 0 && res.data) {
      res.data.forEach((ele:any) => {
        arr.push(ele.id)
      })
    }
    const fetchArr: any[] = []
    await arr.forEach((code: string) => {
      fetchArr.push(getAnalyList({
        analysisCode: 'code0020',
        customerId: CommonMudule.customerId,
        customerMemberId: code
      })
      )
    })
    const resArr = await Promise.all(fetchArr)
    const dataObjArr: any = []
    resArr.forEach(resItem => {
      if (resItem.statusCode === 0 && resItem.data) {
        if (!resItem.data.hide) {
          const tempOpt = cloneDeep(barOption2)
          resItem.data.assetAnalysisItemGraph = formaChartRes(resItem.data.assetAnalysisItemGraph, 'itemName', 'calFieldValue')
          const nameList = resItem.data.assetAnalysisItemGraph.map((v:any) => { return v.name })
          tempOpt.series[0].data = resItem.data.assetAnalysisItemGraph
          tempOpt.xAxis[0].data = nameList
          resItem.data.barOption = tempOpt
          resItem.data.assetAnalysisItemTable && resItem.data.assetAnalysisItemTable.forEach((item: any) => {
            if (item.itemName === '当前覆盖度') {
              item.calFieldValue = item.calFieldValue + '%'
            } else {
              if (item.itemName === '当前缺口') {
                item.color = colors.red
              }
              if (floatReg(item.calFieldValue)) {
                item.calFieldValue = comdify(item.calFieldValue)
              }
            }
          })
        }
        dataObjArr.push(resItem.data)
      }
    })
    console.log('getChildIllData', resArr)

    AnalyModule.SET_CHILD_ILL_OBJ({ childIllObjArr: dataObjArr })
  }

  // 统一请求方法
  async fetchAction(obj: ChartStoreObj, customerMemberId?: string) {
    const fetchArr: any[] = []
    Object.keys(obj).forEach((code: string) => {
      fetchArr.push(getAnalyList({
        analysisCode: code,
        customerId: CommonMudule.customerId,
        customerMemberId
      }))
    })
    const resArr : ChartReqRes[] = await Promise.all(fetchArr)
    return resArr
  }

  // 家庭保障分析
  public async getFamalySecuritObj() {
    const resArr = await this.fetchAction(AnalyModule.famalySecuritObj)
    console.log('getFamalySecuritObj', resArr)
    AnalyModule.SET_FAMALY_OBJ({ famalyStore: resArr })
  }

  // 家庭资产总览
  public async getAnalyListAction() {
    const resArr = await this.fetchAction(AnalyModule.analyObj)
    AnalyModule.SET_ANALY_OBJ({ resArr })
  }

  public async getAnalyListActionOther() {
    const resArrOther = await this.fetchAction(AnalyModule.analyObj2)
    AnalyModule.SET_ANALY_OBJ_OTHER({ resArrOther })
  }

  // 家庭基础保障 31-34
  public async getBasicSecurityObj() {
    const basicSecurityObj = await this.fetchAction(AnalyModule.basicSecurityObj)
    AnalyModule.SET_BASIC_SECURITY_OBJ({ basicSecurityObj })
  }

  // 家庭基础保障 子女 35
  public async getBasicChildArr() {
    const arr:string[] = []
    const res = await fetchChildInfo({ customerId: CommonMudule.customerId })
    if (res.statusCode === 0 && res.data) {
      res.data.forEach((ele:any) => {
        arr.push(ele.id)
      })
    }
    const fetchArr: any[] = []
    await arr.forEach((code: string) => {
      fetchArr.push(getAnalyList({
        analysisCode: 'code0035',
        customerId: CommonMudule.customerId,
        customerMemberId: code
      })
      )
    })
    const resArr = await Promise.all(fetchArr)
    const basicChildArr: any = []
    resArr.forEach(resItem => {
      if (resItem.statusCode === 0 && resItem.data) {
        if (!resItem.data.hide) {
          const tempOpt = cloneDeep(barOption2)
          resItem.data.assetAnalysisItemTable && resItem.data.assetAnalysisItemTable.forEach((item: any) => {
            if (item.itemName === '当前覆盖度') {
              item.calFieldValue = item.calFieldValue + '%'
            } else {
              if (floatReg(item.calFieldValue)) {
                item.calFieldValue = comdify(item.calFieldValue)
              }
            }
          })
          resItem.data.assetAnalysisItemGraph = resItem.data.assetAnalysisItemGraph && formaChartRes(resItem.data.assetAnalysisItemGraph, 'itemName', 'calFieldValue')
          const nameList = resItem.data.assetAnalysisItemGraph && resItem.data.assetAnalysisItemGraph.map((v:any) => { return v.name })
          tempOpt.series[0].data = resItem.data.assetAnalysisItemGraph
          tempOpt.xAxis[0].data = nameList
          resItem.data.barOption = tempOpt
        }

        basicChildArr.push(resItem.data)
      }
    })
    console.log('getChildIllData', resArr)

    AnalyModule.SET_BASIC_CHILD_ARR({ basicChildArr })
  }
}
