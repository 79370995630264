import store from '@/store'
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators'
import { barOption2, riskTypeObj, riskTypeObj2, barOption3, scatterOption, gaugeOption, lineOption, colors, seriesBarColor, seriesCircleColor } from './option'
import { formaChartRes, floatReg } from '@/utils/tools'
import { cloneDeep } from 'lodash'
import { ChartStoreObj, ChartReqRes, LineGrap, AnalyGraph, ItemChart, AnalyItem } from '@/types/analy'
import echarts from 'echarts'
import { comdify } from '@/utils/validate'
import { RiskType } from '@/types/portfolio'
export interface AnalyState {
  analyObj: ChartStoreObj
  analyObj2: ChartStoreObj
}
type ChildObj = {
  [propName: string]: any
}
const t = {
  aliasName: null,
  analysisCode: '',
  analysisName: '',
  assetAnalysisItemGraph: [],
  assetAnalysisItemTable: [],
  colNames: null,
  context: '',
  contextType: '',
  createBy: null,
  createByName: null,
  createTime: null,
  deleted: false,
  graphType: '',
  hide: false,
  id: '',
  lineGraph: null,
  tableType: '',
  tenantId: null
}
@Module({ name: 'analy', dynamic: true, store })
class Analy extends VuexModule implements AnalyState {
  totalAs = {
    total_debt: 0,
    total_asset: 0,
    customerName: ''
  }

  analyObj: ChartStoreObj = {
    code0001: Object.assign({}, t),
    code0002: Object.assign({}, t),
    code0003: Object.assign({}, t),
    code0004: Object.assign({}, t),
    code0005: Object.assign({}, t),
  }

  analyObj2: ChartStoreObj = {
    code0006: Object.assign({}, t),
    code0007: Object.assign({}, t),
    code0008: Object.assign({}, t),
    code0009: Object.assign({}, t),
    code0010: Object.assign({}, t),
  }

  asConfig:ChartStoreObj = {
    code0011: Object.assign({}, t),
    code0012: Object.assign({}, t),
    code0013: Object.assign({}, t),
    code0014: Object.assign({}, t),
  }

  famalySecuritObj: ChartStoreObj = {
    code0016: Object.assign({}, t),
    code0017: Object.assign({}, t),
    code0018: Object.assign({}, t),
    code0019: Object.assign({}, t),
    code0022: Object.assign({}, t),
    code0023: Object.assign({}, t),
  }

  basicSecurityObj: ChartStoreObj = {
    code0031: Object.assign({}, t),
    code0032: Object.assign({}, t),
    code0033: Object.assign({}, t),
    code0034: Object.assign({}, t),
  }

  childTeachObjArr = []
  childIllObjArr = []
  basicChildArr = []

  @Mutation
  SET_TOTAL_AS({ totalAs }: any) {
    console.log('SET_TOTAL_AS', totalAs)
    this.totalAs = totalAs
  }

  @Mutation
  SET_AS_CONFIG({ asConfig }: any) {
    asConfig.forEach((res: ChartReqRes) => {
      if (res.statusCode === 0 && res.data) {
        if (res.data.analysisCode === 'code0011') {
          if (!res.data.hide) {
            res.data.assetAnalysisItemTable = res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.filter(item => Boolean(item.calFieldValue))
            const tempOpt = cloneDeep(barOption2)
            const opt: {name:string, value: RiskType, itemStyle:Object}[] = []
            res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((item, index) => {
              opt.push({
                name: item.itemName,
                // @ts-ignore
                value: riskTypeObj[item.calFieldValue],
                itemStyle: {
                  color: seriesBarColor[index],
                }
              })
            })
            // @ts-ignore
            tempOpt.series[0].data = opt
            tempOpt.tooltip = { // 提示框
              trigger: 'item',
              formatter: function(params: any) {
              // @ts-ignore
                return riskTypeObj2[params.value]
              }// 数据的值
            }
            tempOpt.xAxis[0].data = opt.map((v:any) => { return v.name })
            res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
              if (item.itemName === '检测') {
                if (item.calFieldValue === '高于风险承受能力') {
                  item.color = colors.red
                }

                if (item.calFieldValue === '低于风险承受能力') {
                  item.color = colors.blue
                }
              }
            })
            res.data.barOption = tempOpt
          }
        } else if (res.data.analysisCode === 'code0012') {
          console.log('res.data.analysisCode12', res.data)
          if (!res.data.hide) {
            res.data.assetAnalysisItemTable = res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.filter(item => Boolean(item.calFieldValue))
            const tempOpt = cloneDeep(barOption2)
            const opt: {name:string, value: RiskType, itemStyle: object}[] = []
            res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((item, index) => {
              opt.push({
                name: item.itemName,
                // @ts-ignore
                value: riskTypeObj[item.calFieldValue],
                itemStyle: {
                  color: seriesBarColor[index],
                }
              })
            })
            // @ts-ignore
            tempOpt.series[0].data = opt
            tempOpt.tooltip = { // 提示框
              trigger: 'item',
              formatter: function(params: any) {
              // @ts-ignore
                return riskTypeObj2[params.value]
              }// 数据的值
            }
            tempOpt.xAxis[0].data = opt.map((v:any) => { return v.name })
            res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
              if (item.itemName === '检测') {
                if (item.calFieldValue === '高于风险承受能力') {
                  item.color = colors.red
                }

                if (item.calFieldValue === '低于风险承受能力') {
                  item.color = colors.blue
                }
              }
            })
            res.data.barOption = tempOpt
          }
        } else if (res.data.analysisCode === 'code0013') {
          if (!res.data.hide) {
            const tempOpt = cloneDeep(barOption3)
            console.log('res.data.colNames 13', res.data.colNames)
            if (typeof res.data.colNames === 'string') {
              res.data.colNames = res.data.colNames && (res.data.colNames as string).split(',')
            }
            const temp:any[] = []
            res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((itemGraph:AnalyGraph, index: number) => {
              const a: any[] = []
              a[0] = itemGraph.itemName
              itemGraph.items.forEach((item: ItemChart) => {
                a.push(item.colFieldValue)
              })
              temp.push(a)
            })

            tempOpt.dataset.source = temp
            tempOpt.yAxis.axisLabel.formatter = '{value}%'
            res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((itemArr: any) => {
              itemArr.items.forEach((item:any) => {
                if (item.colName === '资产比重') {
                  item.colFieldValue = item.colFieldValue + '%'
                }
                if (item.colName === '参考权重上限') {
                  item.colFieldValue = item.colFieldValue + '%'
                }

                if (item.colName === '检测') {
                  if (item.colFieldValue === '偏高') {
                    item.color = colors.red
                  }
                  if (item.colFieldValue === '偏低') {
                    item.color = colors.blue
                  }
                }
              })
            })
            res.data.barOption = tempOpt
            console.log('code0013 总资产集中度检查 res.data', res.data)
          }
        } else if (res.data.analysisCode === 'code0014') {
          // res.data.assetAnalysisItemTable = res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.filter(item => Boolean(item.calFieldValue))
          if (!res.data.hide) {
            const temp:any[] = []
            const tempOpt = cloneDeep(scatterOption)
            res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((itemGraph:AnalyGraph, index: number) => {
              const a: any[] = []
              itemGraph.items.forEach((item: ItemChart) => {
                a.push(item.colFieldValue)
              })
              a.push(itemGraph.itemName)
              temp.push(a)
            })
            const filterSeries:any[] = []
            temp.filter((item:any) => item[2] !== 0)
              .map((item: any, index:number) => {
                filterSeries.push({
                  name: '2015',
                  data: [item],
                  type: 'scatter',
                  symbolSize: function(data: number[]) {
                    let size = Math.sqrt(data[2]) / 20
                    size = size > 65 ? 65 : size
                    return size
                  },
                  label: {
                    show: true,
                    formatter: '{@[3]}',
                    color: seriesCircleColor[index] || '#2179ff',
                    position: 'top'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      formatter: function(param: any) {
                        return param.data[3]
                      },
                      position: 'top'
                    }
                  },
                  itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(25, 100, 150, 0.5)',
                    shadowOffsetY: 5,
                    // @ts-ignore
                    color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
                      offset: 0,
                      color: seriesCircleColor[index] || '#2179ff'
                    }, {
                      offset: 1,
                      color: seriesCircleColor[index] || '#2179ff'
                    }])
                  }
                })
              })
            // @ts-ignore
            tempOpt.series = filterSeries
            res.data.barOption = tempOpt
            console.log('code0014  res.data', res.data)
          }
        } else {
          if (!res.data.hide) {
            res.data.assetAnalysisItemTable = res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.filter(item => Boolean(item.calFieldValue))
            const tempOpt = cloneDeep(barOption2)
            const t = formaChartRes(res.data.assetAnalysisItemGraph, 'itemName', 'calFieldValue')
            tempOpt.series[0].data = t
            tempOpt.xAxis[0].data = t.map((v:any) => { return v.name })
            res.data.barOption = tempOpt
          }
        }
        this.asConfig[res.data.analysisCode] = res.data
      }
    })
  }

  @Mutation
  SET_CHILD_ILL_OBJ({ childIllObjArr }: any) {
    this.childIllObjArr = childIllObjArr
  }

  @Mutation
  SET_FAMALY_OBJ({ famalyStore }: any) {
    famalyStore.forEach((res: ChartReqRes) => {
      if (res.statusCode === 0 && res.data) {
        if (res.data.analysisCode !== 'code0023') {
          if (!res.data.hide) {
            const tempOpt = cloneDeep(barOption2)
            res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
              if (item.itemName === '参考值（万元）' || item.itemName === '当前重疾保额（万元）' || item.itemName === '当前人身保额（万元）' || item.itemName === '当前重疾保额（万元）' || item.itemName === '参考值（万元）') {
                if (floatReg(item.calFieldValue)) {
                  item.calFieldValue = comdify(item.calFieldValue)
                }
              }

              if (item.itemName === '当前覆盖度' || item.itemName.includes('覆盖度')) {
                item.calFieldValue = comdify(item.calFieldValue) + '%'
              }

              if (item.itemName === '当前缺口（万元）' || item.itemName === '资产保全缺口（万元）') {
                if (floatReg(item.calFieldValue)) {
                  item.calFieldValue = comdify(item.calFieldValue)
                }
                item.color = colors.red
              }
            })
            const t = formaChartRes(res.data.assetAnalysisItemGraph, 'itemName', 'calFieldValue')
            tempOpt.series[0].data = t
            tempOpt.xAxis[0].data = t.map((v:any) => { return v.name })
            res.data.barOption = tempOpt
            console.log('famalyStore 18', res.data.analysisCode, res.data)
          }
        } else {
          const tempOpt = cloneDeep(lineOption)
          if (!res.data.hide) {
            const xArr: number[] = []
            const yArr: number[] = []
            res.data.lineGraph && res.data.lineGraph.xyList.forEach((graphItem: {x: number, y: number}) => {
              xArr.push(graphItem.x)
              yArr.push(graphItem.y)
            })
            // @ts-ignore
            tempOpt.xAxis[0].data = xArr
            // @ts-ignore
            tempOpt.series[0].data = yArr
            tempOpt.yAxis.name = res.data.lineGraph.yname
            res.data.barOption = tempOpt
            res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
              if (item.itemName === '当前储备总计（万元）' || item.itemName === '参考值（万元）' || item.itemName === '养老金需求额（万元）') {
                if (floatReg(item.calFieldValue)) {
                  item.calFieldValue = comdify(item.calFieldValue)
                }
              }

              if (item.itemName === '当前覆盖率') {
                item.calFieldValue = comdify(item.calFieldValue) + '%'
              }
              if (item.itemName === '当前缺口（万元）') {
                item.color = colors.red
              }
            })
          }
        }
        this.famalySecuritObj[res.data.analysisCode] = res.data
      }
    })
  }

  @Mutation
  SET_ANALY_OBJ({ resArr }: any) {
    resArr.forEach((res: ChartReqRes) => {
      if (res.statusCode === 0 && res.data) {
        if (res.data.analysisCode !== 'code0005') {
          res.data.assetAnalysisItemTable = res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.filter(item => Boolean(item.calFieldValue))
        }
        res.data.assetAnalysisItemGraph = res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.filter(item => Boolean(item.proportion))
        if ((res.data.analysisCode === 'code0004' && typeof res.data.colNames === 'string') || (res.data.analysisCode === 'code0005' && typeof res.data.colNames === 'string')) {
          res.data.colNames = res.data.colNames && (res.data.colNames as string).split(',')
        }
        res.data.analysisName = res.data.analysisName + '(万元)'
        this.analyObj[res.data.analysisCode] = res.data
      }
    })
  }

  @Mutation
  SET_ANALY_OBJ_OTHER({ resArrOther }: any) {
    resArrOther.forEach((res: ChartReqRes) => {
      if (res.statusCode === 0 && res.data) {
        const tempOpt = cloneDeep(gaugeOption)
        if (res.data.analysisCode === 'code0006' && !res.data.hide) {
          res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((item: AnalyGraph) => {
            if (item.itemName === '参考值') {
              // @ts-ignore
              tempOpt.series[0].axisLine.lineStyle.color = [[item.calFieldValue / 100, '#91c7ae'], [1, '#c23531']]
              item.calFieldValue = item.calFieldValue + '%'
            }
            if (item.itemName === '当前数值') {
              // @ts-ignore
              tempOpt.series[0].data[0].value = item.calFieldValue
              item.calFieldValue = item.calFieldValue + '%'
            }
            tempOpt.series[0].data[0].name = res.data.analysisName
          })
          res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
            if (item.itemName === '参考值') {
              item.calFieldValue = item.calFieldValue + '%'
            }
            if (item.itemName === '当前数值') {
              item.calFieldValue = item.calFieldValue + '%'
            }
            if (item.itemName === '检测') {
              if (item.calFieldValue === '偏高') {
                item.color = colors.red
              }
            }
            tempOpt.series[0].data[0].name = res.data.analysisName
          })
          res.data.option = tempOpt
          //
          // console.log(' code0006 tempOpt', tempOpt)
        }

        if (res.data.analysisCode === 'code0007' && !res.data.hide) {
          res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((item: AnalyGraph) => {
            if (item.itemName === '参考值') {
              tempOpt.series[0].detail.formatter = '{value}万'
              const rang = (item.calFieldValue as string).split('-')
              // @ts-ignore
              tempOpt.series[0].axisLine.lineStyle.color = [[parseFloat(rang[0]) / 100, '#91c7ae'], [parseFloat(rang[1]) / 100, '#63869e'], [1, '#c23531']]
            }
            if (item.itemName === '当前数值') {
              // @ts-ignore
              tempOpt.series[0].data[0].value = item.calFieldValue
            }

            tempOpt.series[0].data[0].name = res.data.analysisName
          })
          res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
            if (item.itemName === '当前数值') {
              item.calFieldValue = item.calFieldValue + '万'
            }
            if (item.itemName === '检测') {
              if (item.calFieldValue === '偏高') {
                item.color = colors.red
              }

              if (item.calFieldValue === '偏低') {
                item.color = colors.blue
              }
            }
          })
          // console.log(' code0007 tempOpt', tempOpt)
          res.data.option = tempOpt
        }

        if (res.data.analysisCode === 'code0008' && !res.data.hide) {
          res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((item: AnalyGraph) => {
            if (item.itemName === '参考值') {
              tempOpt.series[0].detail.formatter = '{value}%'
              const rang = (item.calFieldValue as string).split('-')
              // @ts-ignore
              tempOpt.series[0].axisLine.lineStyle.color = [[parseInt(item.calFieldValue) / 100, '#91c7ae'], [1, '#c23531']]
            }
            if (item.itemName === '当前数值') {
              // @ts-ignore
              tempOpt.series[0].data[0].value = item.calFieldValue
            }
            tempOpt.series[0].data[0].name = res.data.analysisName
          })
          res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
            if (item.itemName === '当前数值') {
              item.calFieldValue = item.calFieldValue + '%'
            }
            if (item.itemName === '检测') {
              if (item.calFieldValue === '偏高') {
                item.color = colors.red
              }
            }
          })
          // console.log(' code0008 tempOpt', tempOpt)
          res.data.option = tempOpt
        }

        if (res.data.analysisCode === 'code0009') {
          if (!res.data.hide) {
            res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((item: AnalyGraph) => {
              if (item.itemName === '参考值') {
                tempOpt.series[0].detail.formatter = '{value}%'
                const rang = (item.calFieldValue as string).split('-')
                // @ts-ignore
                tempOpt.series[0].axisLine.lineStyle.color = [[parseFloat(rang[0]) / 100, '#91c7ae'], [parseFloat(rang[1]) / 100, '#63869e'], [1, '#c23531']]
              }
              if (item.itemName === '当前数值') {
              // @ts-ignore
                tempOpt.series[0].data[0].value = item.calFieldValue
              }
              tempOpt.series[0].data[0].name = res.data.analysisName
            })
            res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
              if (item.itemName === '当前数值') {
                item.calFieldValue = item.calFieldValue + '%'
              }
              if (item.itemName === '检测') {
                if (item.calFieldValue === '偏高') {
                  item.color = colors.red
                }

                if (item.calFieldValue === '偏低') {
                  item.color = colors.blue
                }
              }
            })
            // console.log(' code0009 tempOpt', tempOpt)
            res.data.option = tempOpt
          }
        }

        if (res.data.analysisCode === 'code0010') {
          if (!res.data.hide) {
            res.data.assetAnalysisItemGraph && res.data.assetAnalysisItemGraph.forEach((item: AnalyGraph) => {
              if (item.itemName === '参考值') {
                tempOpt.series[0].detail.formatter = '{value}%'
                const rang = (item.calFieldValue as string).split('-')
                // @ts-ignore
                tempOpt.series[0].axisLine.lineStyle.color = [[parseFloat(rang[0]) / 100, '#91c7ae'], [parseFloat(rang[1]) / 100, '#63869e'], [1, '#c23531']]
              }
              if (item.itemName === '当前数值') {
              // @ts-ignore
                tempOpt.series[0].data[0].value = item.calFieldValue
              }
              tempOpt.series[0].data[0].name = res.data.analysisName
            })

            res.data.assetAnalysisItemTable.forEach((item: AnalyItem) => {
              if (item.itemName === '参考值') {
                item.calFieldValue = '100%'
              }
              if (item.itemName === '当前数值') {
                item.calFieldValue = item.calFieldValue + '%'
              }
              if (item.itemName === '检测') {
                if (item.calFieldValue === '财务自由度较低') {
                  item.color = colors.red
                }

                if (item.calFieldValue === '接近实现财务自由') {
                  item.color = colors.blue
                }
              }
              tempOpt.series[0].data[0].name = res.data.analysisName
            })
            // console.log(' code0010 tempOpt', tempOpt)
            res.data.option = tempOpt
          }
        }
        this.analyObj2[res.data.analysisCode] = res.data
      }
    })
  }

  @Mutation
  SET_CHILD_OBJ({ childTeachObj }: any) {
    this.childTeachObjArr = childTeachObj
  }

  @Mutation
  SET_BASIC_CHILD_ARR({ basicChildArr }: any) {
    this.basicChildArr = basicChildArr
  }

  @Mutation
  SET_BASIC_SECURITY_OBJ({ basicSecurityObj }: any) {
    basicSecurityObj.forEach((res: ChartReqRes) => {
      if (res.statusCode === 0 && res.data) {
        if (!res.data.hide) {
          const tempOpt = cloneDeep(barOption2)
          res.data.assetAnalysisItemTable && res.data.assetAnalysisItemTable.forEach((item: any) => {
            if (item.itemName === '当前覆盖度') {
              item.calFieldValue = item.calFieldValue + '%'
            } else {
              if (floatReg(item.calFieldValue)) {
                item.calFieldValue = comdify(item.calFieldValue)
              }
            }
          })
          const t = formaChartRes(res.data.assetAnalysisItemGraph, 'itemName', 'calFieldValue')
          tempOpt.series[0].data = t
          tempOpt.xAxis[0].data = t.map((v:any) => { return v.name })
          res.data.barOption = tempOpt
        }
        this.basicSecurityObj[res.data.analysisCode] = res.data
      }
    })
  }
}

export const AnalyModule = getModule(Analy)
